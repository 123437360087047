<template>
  <div
    class="ui-expand"
    :class="isOpen ? expandedClass : null"
  >
    <div
      class="expand-trigger"
      @mousedown="setOpen(true)"
    >
      <slot name="trigger"></slot>
    </div>

    <transition
      name="trn-expand"
      @before-enter="beforeEnter"
    >
      <div
        :class="['ui-expand-contents', contentsClass]"
        v-show="isOpen"
        :style="{position: position}"
      >
        <div
          v-if="!hideHeader && !showCloseButton"
          :class="['ui-expand-toolbar', toolbarClass]"
        >
          <div
            class="toolbar-arrow"
            @mousedown="setOpen(false)"
          >
            <ui-icon value="g:arrow_back"></ui-icon>
          </div>
          <div class="toolbar-contents">
            <slot
              name="header"
              :close="doClose"
            >
              <!-- clone the trigger in the toolbar when no #header is set -->
              <slot name="trigger"></slot>
            </slot>
          </div>
        </div>

        <button
          v-if="showCloseButton"
          class="expand-close-btn"
          type="button"
          @mousedown="setOpen(false)"
        >&times;</button>

        <slot
          v-if="componentLoaded"
          name="contents"
          :open="doOpen"
          :close="doClose"
        ></slot>
      </div>
    </transition>
  </div>
</template>

<script>
// https://gist.github.com/twxia/bb20843c495a49644be6ea3804c0d775
function getScrollParent(node) {
  if (!node) return null;
  else if (node.scrollTop > 0) return node;
  return getScrollParent(node.parentNode) || document.documentElement;
}

import Vue from "vue";

/*
Keeps track of all open expands, and react to browser back button to close them
(also works in cordova)
*/
var EventBus = new Vue({
  data: {
    lastId: 0,
    items: []
  },

  methods: {
    open(item) {
      this.items.push(item);
      history.pushState("ui-expand-history", "", "");
    },

    // expand closed "manually" (i.e. not by a window event)
    close() {
      history.go(-1);
    },

    onPopstate() {
      if (!this.items.length) {
        return;
      }

      // Close item and remove from buffer
      this.items[this.items.length - 1].isOpen = false;
      this.items.splice(this.items.length - 1, 1);
    }
  }
});

window.addEventListener("popstate", EventBus.onPopstate);

import { UiIcon } from "@/modules/ui/components";

export default {
  name: "ui-expand",
  components: { UiIcon },

  props: {
    open: {
      type: Boolean,
      default: false
    },

    position: {
      type: String,
      default: "absolute" // absolute  o  fixed
    },

    hideHeader: {
      type: Boolean,
      default: false
    },

    showCloseButton: {
      type: Boolean,
      default: false
    },

    // Forces the zoom window to left:0, right: 0
    fullWidth: {
      type: Boolean,
      default: false
    },

    // Classname to give to the content element
    contentsClass: {
      type: String,
      required: false,
      default: null
    },

    // Classname to give to the toolbar element
    toolbarClass: {
      type: String,
      required: false,
      default: null
    },

    // Classname to give root element then expanded
    expandedClass: {
      type: String,
      required: false,
      default: null
    },

    duration: {
      type: String,
      required: false,
      default: "0.3s"
    }
  },

  data() {
    return {
      isOpen: null,
      componentLoaded: false
    };
  },

  watch: {
    open: {
      immediate: true,
      handler(newValue) {
        this.isOpen = newValue;
        if (this.isOpen) {
          this.componentLoaded = true;
        }
      }
    },

    duration(newValue) {
      this.$el.style.setProperty("--ui-expand-duration", newValue);
    }
  },

  mounted() {
    this.$el.style.setProperty("--ui-expand-duration", this.duration);
  },

  methods: {
    doOpen() {
      return this.setOpen(true);
    },

    doClose() {
      return this.setOpen(false);
    },

    setOpen(newValue) {
      this.$emit("update:open", newValue);
      this.isOpen = newValue;

      if (newValue) {
        this.componentLoaded = true;
        EventBus.open(this);
      } else {
        EventBus.close(this);
      }

      // Determine the trigger element's global left offset (when in fullWidth mode)
      if (newValue && this.fullWidth) {
        let triggerElement = this.$el.querySelector(".expand-trigger");
        if (!triggerElement) {
          // well that just shouldn't happen
          return;
        }

        let offsetLeft = triggerElement.getBoundingClientRect().left;
        this.$el.style.setProperty(
          "--ui-expand-offset-left",
          offsetLeft + "px"
        );
      }
    },

    // Transition events
    beforeEnter(el) {
      let top, right, bottom, left;
      let originElement = this.$el.querySelector(".expand-trigger");

      if (this.position == "fixed") {
        let rect = originElement.getBoundingClientRect();
        top = rect.top;
        right = document.documentElement.clientWidth - rect.right;
        bottom = document.documentElement.clientHeight - rect.bottom;
        left = rect.left;
      } else {
        let parentScrollTop = getScrollParent(originElement).scrollTop;

        top = originElement.offsetTop - parentScrollTop;
        right =
          originElement.offsetParent.offsetWidth -
          (originElement.offsetLeft + originElement.offsetWidth);
        bottom =
          originElement.offsetParent.offsetHeight -
          (originElement.offsetTop + originElement.offsetHeight) +
          parentScrollTop;
        left = originElement.offsetLeft;
      }

      if (this.fullWidth) {
        left = 0;
        right = 0;
      }

      el.style.setProperty("--expand-origin-top", top + "px");
      el.style.setProperty("--expand-origin-right", right + "px");
      el.style.setProperty("--expand-origin-bottom", bottom + "px");
      el.style.setProperty("--expand-origin-left", left + "px");

      // Quickly switch the expanded class on and off, to make sure CSS transitions
      // into the expanded class are triggered
      if (this.expandedClass) {
        this.$el.classList.remove(this.expandedClass);
        setTimeout(() => this.$el.classList.add(this.expandedClass), 0);
      }
    }
  }
};
</script>

<style lang="scss">
.ui-expand {
  --ui-expand-duration: 0.3s;
  --ui-expand-offset-left: 0;

  .expand-trigger {
    display: block;
  }

  &.--inline {
    .expand-trigger {
      display: inline-block;
    }
  }

  .ui-expand-contents {
    z-index: 9;
    // overflow: auto;
    overflow: hidden;
    overflow-y: scroll;
    // -webkit-overflow-scrolling: touch; // fucks up toolbar z-index in ios
    // overscroll-behavior: none;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: #fff;
  }

  // Toolbar
  .ui-expand-toolbar {
    z-index: 5;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    // !!! testing
    position: sticky;
    top: 0;
    background-color: #fff;
    //

    transition: background-color var(--ui-expand-duration);

    .toolbar-arrow {
      z-index: 2;
      position: absolute;

      color: rgba(0, 0, 0, 0.7);

      top: 0;
      left: 0;
      bottom: 0;
      width: 48px;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: opacity var(--ui-expand-duration);
      opacity: 1;
    }

    .toolbar-contents {
      flex: 1;
      transition: transform var(--ui-expand-duration);
      transform: translate3d(48px, 0, 0);
      margin-right: 48px; // prevent overflow to the right
    }
  }

  .expand-close-btn {
    cursor: pointer;
    outline: none;

    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;

    border: none;
    background: none;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;

    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 26px;
  }

  /* Transition classes */
  .ui-expand-contents {
    &.trn-expand-enter-active,
    &.trn-expand-leave-active {
      transition: var(--ui-expand-duration);
      overflow: hidden;
      white-space: nowrap;
    }

    &.trn-expand-enter,
    &.trn-expand-leave-to {
      top: var(--expand-origin-top);
      right: var(--expand-origin-right);
      bottom: var(--expand-origin-bottom);
      left: var(--expand-origin-left);

      .ui-expand-toolbar {
        background-color: transparent !important;

        .toolbar-arrow {
          opacity: 0;
        }
        .toolbar-contents {
          transform: translate3d(var(--ui-expand-offset-left), 0, 0);
        }
      }
    }

    &.trn-expand-enter-to,
    &.trn-expand-leave {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>